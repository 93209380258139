import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import holidaysService from "./holidays.service";

const initialState = {
    holidays: null,

    isError: false,
    isSuccess: false,
    isLoading: false,
    message: null,

    createHolidaysIsError: false,
    createHolidaysIsSuccess: false,
    createHolidaysIsLoding: false,
    createHolidaysMessage: null,

    updateHolidaysIsError: false,
    updateHolidaysIsSuccess: false,
    updateHolidaysIsLoding: false,
    updateHolidaysMessage: null,

    deleteHolidaysIsError: false,
    deleteHolidaysIsSuccess: false,
    deleteHolidaysIsLoding: false,
    deleteHolidaysMessage: null
}

// Create a holiday
export const createHolidaySlice = createAsyncThunk(
    'holidays/create', async (data, thunkAPI) => {
        try {
            const createHoliday = await holidaysService.createHoliday(data);

            return createHoliday;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// fetch holidays
export const fetchHolidaysSlice = createAsyncThunk(
    'holidays', async (_, thunkAPI) => {
        try {
            const holidays = await holidaysService.fetchHolidays();

            return holidays;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// Update a holiday
export const updateHolidaySlice = createAsyncThunk(
    'holidays/update', async ({ id, data }, thunkAPI) => {
        try {
            const updateHolidays = await holidaysService.updateHolidays(id, data);

            return updateHolidays;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// Delete a holiday
export const deleteHolidays = createAsyncThunk(
    'holidays/delete', async (id, thunkAPI) => {
        try {
            const deleteHoliday = await holidaysService.deleteHolidays(id);

            return deleteHoliday;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const holidaysSlices = createSlice({
    name: 'holidays',
    initialState,
    reducers: {
        resetHolidays: state => {
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.message = false
        },

        resetCreateHolidays: state => {
            state.createHolidaysIsError = false
            state.createHolidaysIsSuccess = false
            state.createHolidaysIsLoding = false
            state.createHolidaysMessage = null
        },

        resetUpdateHolidays: state => {
            state.updateHolidaysIsSuccess = false
            state.updateHolidaysIsError = false
            state.updateHolidaysIsLoding = false
            state.updateHolidaysMessage = null
        },

        resetDeleteHolidays: state => {
            state.deleteHolidaysIsError = false
            state.deleteHolidaysIsSuccess = false
            state.deleteHolidaysIsLoding = false
            state.deleteHolidaysMessage = null
        }
    },
    extraReducers: builder => {
        builder
            // fetch holidays
            .addCase(fetchHolidaysSlice.pending, state => {
                state.isLoading = true
            })
            .addCase(fetchHolidaysSlice.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.holidays = action.payload?.data
                state.message = action.payload?.message
            })
            .addCase(fetchHolidaysSlice.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            // create holiday
            .addCase(createHolidaySlice.pending, state => {
                state.createHolidaysIsLoding = true
            })
            .addCase(createHolidaySlice.fulfilled, (state, action) => {
                state.createHolidaysIsLoding = false
                state.createHolidaysIsSuccess = true
                state.holidays = action.payload?.data
                state.createHolidaysMessage = action.payload?.message
            })
            .addCase(createHolidaySlice.rejected, (state, action) => {
                state.createHolidaysIsLoding = false
                state.createHolidaysIsError = true
                state.createHolidaysMessage = action.payload
            })

            // update holidays
            .addCase(updateHolidaySlice.pending, state => {
                state.updateHolidaysIsLoding = true
            })
            .addCase(updateHolidaySlice.fulfilled, (state, action) => {
                state.updateHolidaysIsLoding = false
                state.updateHolidaysIsSuccess = true
                state.updateHolidaysMessage = action.payload?.message
                state.holidays = action.payload?.data
            })
            .addCase(updateHolidaySlice.rejected, (state, action) => {
                state.updateHolidaysIsLoding = false
                state.updateHolidaysIsError = true
                state.updateHolidaysMessage = action.payload
            })

            // delete holidays
            .addCase(deleteHolidays.pending, state => {
                state.deleteHolidaysIsLoding = true
            })
            .addCase(deleteHolidays.fulfilled, (state, action) => {
                state.deleteHolidaysIsLoding = false
                state.deleteHolidaysIsSuccess = true
                state.holidays = action.payload?.data
                state.deleteHolidaysMessage = action.payload?.message
            })
            .addCase(deleteHolidays.rejected, (state, action) => {
                state.deleteHolidaysIsLoding = false
                state.deleteHolidaysIsError = true
                state.deleteHolidaysMessage = action.payload
            })

    }
})

export const { resetHolidays, resetCreateHolidays, resetUpdateHolidays, resetDeleteHolidays } = holidaysSlices.actions;
export default holidaysSlices.reducer;