import React, { useEffect, useState } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Modal
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


import { getServicesSlice } from "../features/services/services-details.slice"
import { bookService } from '../features/booking/booking.slice';
import { unavailableDatesSlice, availableHoursOfDaySlice } from '../features/workingHours/working-hours.slice';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const BookPage = () => {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { userId, userEmail, phonenumber } = useSelector(state => state.auth);
    const { serviceDetails, bookedService } = useSelector(state => state.serviceDetails);
    const { unavailableDates, availableHoursOfDay } = useSelector(state => state.workingHours);

    const [selectedServiceId, setSelectedServiceId] = useState(bookedService?.id);
    const [selectedServiceTitle, setSelectedServiceTitle] = useState(bookedService?.title);
    const [price, setPrice] = useState(bookedService?.price);

    // when a date changes fetch time available for that date and clean time input
    const onDateChange = (value) => {
        const date = dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : null;
        dispatch(availableHoursOfDaySlice(date));
        form.setFieldsValue({ time: undefined });
    }

    // Submit booking data bookService slice
    const onFinish = (values) => {
        const date = dayjs(values?.date).format('YYYY-MM-DD');
        const dateTime = dayjs(`${date}T${values?.time}`).format('YYYY-MM-DDTHH:mm:ss');
        const data = {
            name: `${values?.firstname} ${values?.lastname}`,
            phonenumber: values?.phonenumber,
            email: values?.email,
            serviceId: values?.serviceId,
            date: dateTime
        }
        Modal.confirm({
            title: 'Confirm Booking',
            content: (
                <div>
                    You are booking <strong>{selectedServiceTitle}</strong> service on{' '}
                    <span style={{ color: 'blue' }}>{dayjs(dateTime).format('ddd, DD MMM YYYY HH:mm')}</span>.
                </div>
            ),
            okText: 'Confirm',
            cancelText: 'Cancel',
            okButtonProps: {
                style: {
                    backgroundColor: '#4CAF50', // Custom background color
                    borderColor: '#4CAF50', // Custom border color
                    color: '#ffffff', // Custom text color
                },
            },
            onOk() {
                console.log(data)
                // Proceed with booking
                dispatch(bookService(data));
                navigate('/')
            },
            onCancel() {
                // Cancel the booking
            },
        })
    }

    // In case a user want to change a service
    const handleServiceChange = (val) => {
        const serviceToBook = serviceDetails?.find(service => service.id === val);
        setSelectedServiceId(val);
        setSelectedServiceTitle(serviceToBook?.title)
        setPrice(serviceToBook?.price);
    }

    // dates available to book
    const disabledDate = (date) => {
        const findDate = unavailableDates?.holidays?.find((holiday) => dayjs(date).format('MM-DD') === dayjs(holiday?.date).format('MM-DD'));

        if (date.endOf('d').valueOf() < dayjs()) {
            return true;
        }
        if (unavailableDates?.bookedDates?.includes(dayjs(date).format('YYYY-MM-DD'))) {
            return true;
        }
        if (findDate?.occurring && dayjs(date).format('MM-DD') === dayjs(findDate?.date).format('MM-DD')) {
            return true;
        }
        if (findDate?.occurring === false && dayjs(date).format('YYYY-MM-DD') === dayjs(findDate?.date).format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        // dispatch(getServicesSlice());
        dispatch(unavailableDatesSlice());
    }, []);

    useEffect(() => {
        form.setFieldsValue({ serviceId: selectedServiceId });
    }, [selectedServiceId, form])

    // Warn user on reload or navigation away
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Chrome requires this line
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [form]);

    return (
        <div className='w-full mt-32 h-[100vh] flex justify-center'>
            <Form
                form={form}
                name='bookingPage'
                labelAlign='left'
                wr
                labelCol={{
                    // xs: { span: 8 },   // Label occupies 8/24 columns on small screens
                    md: { span: 6 },   // Label occupies 6/24 columns on medium screens
                    xl: { span: 4 },   // Label occupies 4/24 columns on large screens
                }}
                wrapperCol={{
                    // xs: { span: 16 },  // Input occupies 16/24 columns on small screens
                    md: { span: 18 },  // Input occupies 18/24 columns on medium screens
                    xl: { span: 20 },  // Input occupies 20/24 columns on large screens
                }}
                layout="horizontal"
                initialValues={{
                    // email: userEmail,
                    // phonenumber,
                    serviceId: selectedServiceId,
                    size: 'default',
                }}
                size="default"
                className='w-[575px] h-[80%] mx-5'
                onFinish={onFinish}
            >
                <p className='text-3xl font-bold mb-5 text-center'>Book</p>

                <Form.Item
                    label="Prénom"
                    className='text-xl font-bold'
                    name='firstname'
                    rules={[{ required: true, message: 'Prénom Obligatoire!' }]}
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    label="Nom"
                    className='text-xl font-bold'
                    name='lastname'
                    rules={[{ required: true, message: 'Nom de famille Obligatoire!' }]}
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    label="Téléphone"
                    className='text-xl font-bold'
                    name='phonenumber'
                    rules={[{ required: true, message: 'Téléphone Obligatoire!' }]}
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    label="Email"
                    className='text-xl font-bold'
                    name='email'
                    rules={[{ required: true, message: 'Email Obligatoire!' }]}
                >
                    <Input type='email' />
                </Form.Item>

                <Form.Item
                    name='serviceId'
                    label="Service"
                    className='text-xl font-bold'
                    rules={[{ required: true, message: 'Service Obligatoire!' }]}
                >
                    <div className='w-full flex gap-x-10'>
                        <Select
                            placeholder="Select a service"
                            className='w-full border-red-400'
                            onChange={handleServiceChange}
                            value={selectedServiceId}
                            allowClear
                        >
                            {serviceDetails.map(({ id, title }) => {
                                return <Select.Option key={id} value={id}>{title}</Select.Option>
                            })}
                        </Select>
                        <div className='w-[25%] flex justify-end items-center text-lg'>{price}</div>
                    </div>
                </Form.Item>

                <div className='flex w-full flex-row justify-between items-end'>
                    <Form.Item
                        name='date'
                        label='Date'
                        className='text-xl w-[60%] font-bold'
                        rules={[{ required: true, message: 'Select a date!' }]}
                    >
                        <DatePicker
                            className='md:ml-9 w-[87.7%]'
                            onChange={onDateChange}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        name='time'
                        labelAlign='left'
                        className='text-xl w-[40%] font-bold'
                        rules={[{ required: true, message: 'Select a time!' }]}
                    >
                        <Select
                            placeholder='Select time'
                            className='md:ml-9 w-[100%]'
                            allowClear
                        >
                            {Array.isArray(availableHoursOfDay) && availableHoursOfDay?.map((hour) => (
                                <Select.Option key={hour} value={hour}>{hour}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <Form.Item
                    className='w-full mt-10 flex justify-end items-end'
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='flex items-center text-xl font-bold bg-blue-600 hover:bg-blue-500 text-white rounded-lg'
                    >Confirm</Button>
                </Form.Item>

            </Form>
        </div>
    );
};
export default BookPage;