import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth.slice";
import servicesReducer from "../features/services/services-details.slice";
import switchComponent from "../features/components/component.slice";
import bookingReducer from '../features/booking/booking.slice';
import holidaysReducer from '../features/holidays/holidays.slice';
import workingHoursReducer from '../features/workingHours/working-hours.slice';
import homeImagesReducer from '../features/homeImages/home.image.slice';

// This creates a Redux store, and also automatically configure the Redux DevTools extension so that you can inspect the store while developing.
export const store = configureStore({
    reducer: {
        auth: authReducer,
        serviceDetails: servicesReducer,
        switchComponent,
        booking: bookingReducer,
        holidays: holidaysReducer,
        workingHours: workingHoursReducer,
        homeImages: homeImagesReducer,
    }
})