import api from "../../utils/baseURL";

// Create a service
const createService = async (data) => {

    const response = await api.post(`services/create`, data);

    return response.data;
}

// Update a service
const updateService = async (data, id) => {

    const response = await api.patch(`services/update/${id}`, data)

    return response.data;
}

// Delete a service
const deleteService = async (id) => {

    const response = await api.delete(`services/delete/${id}`);

    return response.data;
}

// Get all services
const getServices = async () => {
    const response = await api.get("services/")

    return response.data;
}

export default { createService, updateService, deleteService, getServices }