// NotFound.js
import React from 'react';

const NotFound = () => {
    return (
        <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
            <h1 className='text-9xl font-bold'>404</h1>
            <p className='text-2xl text-center'>Sorry, the page you're looking for doesn't exist.</p>
        </div>
    );
};

export default NotFound;
