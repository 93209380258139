import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Switch,
    Popconfirm,
    Table,
    Typography,
    TimePicker,
    DatePicker
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';


import { fetchHolidaysSlice, createHolidaySlice, updateHolidaySlice, deleteHolidays } from '../../../features/holidays/holidays.slice';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const placeHolder = title === 'Date' ? 'Date (YYYY-MM-DD)' : title === 'Occurring' ? 'true or false' : 'Name';
    const inputNode = dataIndex === 'occurring' ?
        (
            <Switch className='bg-gray-500' />
        ) : dataIndex === 'offTime' ?
            (
                <TimePicker.RangePicker
                    format='HH:mm'
                />
            ) : dataIndex === 'date' ?
                (
                    <DatePicker
                        format='YYYY-MM-DD'
                        defaultValue={record.date ? dayjs(record.date) : null} // Ensure valid date
                    />
                ) : (
                    <Input placeholder={placeHolder} />
                );

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={(dataIndex !== 'offTime' && dataIndex !== 'occurring') && [
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                // Conditionally render the Switch if children[1] is a boolean
                dataIndex === 'occurring' ? (
                    <Switch className='bg-gray-500' checked={record.occurring} />
                ) : (
                    children
                )
            )}
        </td>
    );
};
const App = () => {
    const dispatch = useDispatch();
    const { holidays } = useSelector(state => state.holidays);
    const { allowedToManage } = useSelector(state => state.auth);

    const [form] = Form.useForm();
    const [data, setData] = useState(holidays);
    const [editingKey, setEditingKey] = useState('');
    const [createHoliday, setCreateHoliday] = useState(false);

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const isEditing = (record) => record.id === editingKey;
    const edit = (record) => {
        const formattedOffTime = record.offTime && record.offTime.length === 2
            ? [dayjs(record.offTime[0]), dayjs(record.offTime[1])]
            : null; // Handle empty or invalid offTime
        form.setFieldsValue({
            date: record.date ? dayjs(record.date) : '',
            name: record.name || '',
            occuring: record.occuring,
            offTime: formattedOffTime,
        });

        const date = form.getFieldValue('date');
        const name = form.getFieldValue('name');

        if (date && name) {
            setIsSaveDisabled(false);  // Enable Save button if both fields are filled
        } else {
            setIsSaveDisabled(true);   // Disable Save button if any required field is missing
        }

        setEditingKey(record.id);
    };
    const cancel = () => {
        setEditingKey('');
        setData(holidays);
    };
    const save = async (key) => {
        const row = await form?.validateFields();
        const updatedRow = await {
            ...row,
            date: row.date ? row.date.format('YYYY-MM-DD') : null, // Convert dayjs to string
        };
        // console.log(row)
        // if (!updatedRow.date) {
        //     throw new Error('Date is required'); // Handle case where date is missing
        // }
        if (createHoliday) {
            dispatch(createHolidaySlice(updatedRow));
            setCreateHoliday(false);
            setEditingKey('');
            setData(holidays);
        } else {
            dispatch(updateHolidaySlice({ id: key, data: updatedRow }));
            setEditingKey('');
            setData(holidays);
        }
    };
    const handleDelete = (key) => {
        dispatch(deleteHolidays(key));
    };
    const handleAdd = () => {
        const newData = {
            id: 0,
            date: '',
            name: ``,
        };
        setData([...data, newData]);
        setEditingKey(newData.id);
        setCreateHoliday(true);
    };

    // Function to check if required fields have values
    const checkFormValidity = () => {
        const date = form.getFieldValue('date');
        const name = form.getFieldValue('name');

        if (date && name) {
            setIsSaveDisabled(false);  // Enable Save button if both fields are filled
        } else {
            setIsSaveDisabled(true);   // Disable Save button if any required field is missing
        }
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            width: '20%',
            editable: true,
            render: (date) => {
                if (date) {
                    const dates = dayjs(date).format('YYYY-MM-DD');
                    return dates;
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '20%',
            editable: true,
        },
        {
            title: 'Occurring',
            dataIndex: 'occurring',
            width: '10%',
            editable: true,
        },
        {
            title: 'Off Time',
            dataIndex: 'offTime',
            width: '25%',
            editable: true,
            render: (offTime) => {
                if (offTime?.length > 0 && dayjs(offTime[0]).isValid() && dayjs(offTime[1]).isValid()) {
                    const startTime = dayjs(offTime[0]).format('HH:mm');
                    const endTime = dayjs(offTime[1]).format('HH:mm');
                    return `${startTime} - ${endTime}`;
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '10%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span className='flex gap-x-1'>
                        <Popconfirm
                            className='text-green-600 cursor-pointer hover:text-green-400 hover:underline text-[80%] md:text-[100%]'
                            title="Click Ok to save"
                            onConfirm={() => save(record.id)}
                            okButtonProps={{ style: { backgroundColor: "#2563eb" } }}
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Popconfirm>
                        <p
                            className='text-[80%] md:text-[100%] text-blue-500 hover:text-sky-400 hover:underline cursor-pointer'
                            onClick={cancel}>
                            Cancel
                        </p>
                    </span>
                ) : (
                    <div className='flex gap-x-4'>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined />
                        </Typography.Link>
                        <Popconfirm
                            className='text-red-400'
                            title="Sure to delete?"
                            onConfirm={() => handleDelete(record.id)}
                            okButtonProps={{ style: { backgroundColor: "#2563eb" } }}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => {
                return {
                    record,
                    inputType: col.dataIndex === 'occurring' ? 'boolean' : col.dataIndex === 'offTime' ? 'offTime' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }
            },
        };
    });

    useEffect(() => {
        dispatch(fetchHolidaysSlice())
    }, []);

    useEffect(() => {
        setData(holidays)
    }, [holidays]);

    // useEffect(() => {
    //     // onFieldsChange is used to track changes to form fields
    //     const unsubscribe = form.onFieldsChange(() => {
    //         checkFormValidity();
    //     });

    //     return () => unsubscribe(); // Cleanup when component unmounts
    // }, [form]);

    return (
        allowedToManage ?
            <div>
                <Button
                    className='bg-blue-600'
                    onClick={handleAdd}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    Add holiday
                </Button>
                <Form
                    form={form}
                    component={false}
                    onFieldsChange={checkFormValidity} // Attach the handler here
                >
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </Form>
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>

    );
};
export default App;