import React from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';

import WorkingHoursTab from './tabs/schedule/WorkingHours';
import HolidaysTab from './tabs/schedule/Holidays';

const { TabPane } = Tabs;

const Schedule = () => {
    const { allowedToManage } = useSelector(state => state.auth);

    return (
        allowedToManage ?
            <div className='mx-3 md:mx-32 my-[100px]'>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Working Hours" key="1">
                        <WorkingHoursTab />
                    </TabPane>
                    <TabPane tab="Holidays" key="2">
                        <HolidaysTab />
                    </TabPane>
                </Tabs>
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>
    )
}

export default Schedule;