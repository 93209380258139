import api from '../../utils/baseURL';

// fetch all working hours
const fetchWorkingHours = async () => {
    const response = await api.get('/working-hours');

    return response.data;
}

// update working hours of any day
const updateWorkingHours = async (data) => {
    const response = await api.patch(`/working-hours`, data);

    return response.data;
}

// fetch unavailable dates that are ahead of today's date
const unavailableDates = async () => {
    const response = await api.get('/working-hours/unavailable-dates');

    return response.data;
}

// Get available hours that belongs to one specific day while booking a service
const availableHoursOfDay = async (date) => {
    const response = await api.get(`/working-hours/day-available-hours?date=${date}`);

    return response.data;
}

export default { fetchWorkingHours, updateWorkingHours, unavailableDates, availableHoursOfDay }