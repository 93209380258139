import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    switchEdit: null,
    switchCreate: null,
    loginBeforeBooking: false,
    multipleImageStatus: false,
};

const switchSlice = createSlice({
    name: 'editService',
    initialState,
    reducers: {
        switchEditOn: state => { state.switchEdit = true },
        switchEditOff: state => { state.switchEdit = false },
        switchCreateOn: state => { state.switchCreate = true },
        switchCreateOff: state => { state.switchCreate = false },
        switchOnLoginBeforeBooking: state => { state.loginBeforeBooking = true },
        switchOffLoginBeforeBooking: state => { state.loginBeforeBooking = false },
        switchMultipleImageModalOn: state => { state.multipleImageStatus = true },
        switchMultipleImageModalOff: state => { state.multipleImageStatus = false },
    }
})

export const {
    switchEditOn,
    switchEditOff,
    switchCreateOn,
    switchCreateOff,
    switchOnLoginBeforeBooking,
    switchOffLoginBeforeBooking,
    switchMultipleImageModalOn,
    switchMultipleImageModalOff,
} = switchSlice.actions;
export default switchSlice.reducer;