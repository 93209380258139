import api from '../../utils/baseURL';

// ---------------------------------------- save booking -------------------------------------------------
const bookService = async (bookingData) => {
    const response = await api.post(`booking`, bookingData);

    return response.data;
}

// ---------------------------------------- unavailable dates that are ahead ------------------------------
const unavailableDates = async () => {
    const response = await api.get(`unavailable-dates`);

    return response.data;
}

// ---------------------------------------- get next bookings ------------------------------------------------
const getNextBookings = async () => {
    const response = await api.get(`next-bookings`);

    return response.data;
}

// ---------------------------------------- get past bookings ------------------------------------------------
const getPastBookings = async () => {
    const response = await api.get(`past-bookings`);

    return response.data;
}

// ---------------------------------------- Update status' booking ------------------------------------------------
const updateBookingStatus = async (id, data) => {
    const response = await api.patch(`booking-status/${id}`, data);

    return response.data;
}

// ---------------------------------------- Get user upcoming booking ------------------------------------------------
const cancelBooking = async (bookingId, email) => {
    const response = await api.get(`booking/cancel?bookingId=${bookingId}&email=${email}`);

    return response.data;
}

// ---------------------------------------- Get all users --------------------------------
const getAllUsers = async () => {
    const response = await api.get(`users`);

    return response.data;
}

export default {
    bookService,
    unavailableDates,
    getNextBookings,
    getPastBookings,
    updateBookingStatus,
    cancelBooking,
    getAllUsers
};