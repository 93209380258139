import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Dropdown, Space, Table, Tag } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { updateBookingStatus } from '../../../features/booking/booking.slice';
dayjs.extend(utc);

const PastBookings = () => {
    const dispatch = useDispatch();
    const { pastBookingData } = useSelector(state => state.booking);
    const { allowedToManage } = useSelector(state => state.auth);

    const handleMenuClick = (id, action) => {
        const data = {
            status: action
        };
        dispatch(updateBookingStatus({ id, data }));
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'PhoneNumber',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => {
                const color = status === 'succeeded' ? 'green' : status === 'pending' ? 'geekblue' : status === 'failed' ? 'volcano' : 'magenta';
                return (
                    <Tag color={color}>
                        {status.charAt().toUpperCase() + status.slice(1)}
                    </Tag>
                )
            }
        },
        {
            title: 'Action',
            key: 'operation',
            render: (_, record) => {
                const items = [
                    {
                        key: 1,
                        label: 'Succeeded',
                        onClick: () => handleMenuClick(record?.key, 'succeeded'),
                    },
                    {
                        key: 2,
                        label: 'Failed',
                        onClick: () => handleMenuClick(record?.key, 'failed'),
                    },
                    {
                        key: 1,
                        label: 'Cancelled',
                        onClick: () => handleMenuClick(record?.key, 'cancelled'),
                    },
                    {
                        key: 2,
                        label: 'Pending',
                        onClick: () => handleMenuClick(record?.key, 'pending'),
                    },
                ];

                return (
                    <Space>
                        <Dropdown
                            menu={{ items }}
                        >
                            <a className='text-sky-500'>
                                Edit <DownOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                )
            }
        }
    ];

    const data = pastBookingData?.map(data => {
        const results = {
            key: data?.id,
            name: data?.name,
            email: data?.email,
            phoneNumber: data?.phonenumber,
            service: data?.service?.title,
            date: `${dayjs(data?.date).utc().format('YYYY-MM-DD HH:mm')}`,
            status: data?.status,
        }

        return results;
    })

    return (
        allowedToManage ?
            <div className='flex flex-col items-center md:mx-3 lg:mx-10'>
                <p className='mb-5 text-xl font-bold'>Past Bookings</p>
                <Table
                    className='overflow-x-auto w-full'
                    columns={columns}
                    dataSource={data}
                    pagination={true}
                    scroll={{ x: 'max-content' }}
                />
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>
    )
};
export default PastBookings;