import Cookies from "js-cookie";


import api from "../../utils/baseURL";

// Register user
const register = async (userData) => {
    const response = await api.post(`auth/register`, userData);
    // const accessToken = response.data.accessToken;
    // const refreshToken = response.data.refreshToken;
    // if (accessToken && refreshToken) {
    //     localStorage.setItem('access_token', accessToken);
    //     localStorage.setItem('refresh_token', refreshToken);
    // }

    return response.data;
}

// Login user
const login = async userData => {
    const response = await api.post(`auth/login`, userData);
    // const accessToken = response.data.accessToken;
    // console.log(response.data);
    // const refreshToken = response.data.refreshToken;
    // if (accessToken && refreshToken) {
    //     localStorage.setItem('access_token', accessToken);
    //     localStorage.setItem('refresh_token', refreshToken);
    // }

    return response.data;
}

// Logout user
const logout = async () => {
    Cookies.remove('tycoon_access_token');
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("refresh_token");
    // localStorage.removeItem("role");

    return {
        data: "Successfully logged out"
    }
}


const authService = {
    register, login, logout
}

export default authService;