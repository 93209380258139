import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { CloseOutlined } from "@ant-design/icons";


import { register } from "../features/auth/auth.slice";
import { getServicesSlice } from "../features/services/services-details.slice";

const Register = props => {
    const divStyle = {
        display: props?.displayRegisterModal === "signup" ? 'block' : 'none'
    }

    function closeRegisterModal() {
        // props.closeRegisterModal()
        props.setModal(null)
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [registerData, setRegisterData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        password: '',
        confirmPassword: '',
    })

    const { firstname, lastname, email, phonenumber, password, confirmPassword } = registerData;

    const { userEmail, isError, isSuccess } = useSelector(state => state.auth);

    useEffect(() => {
        if (isSuccess || userEmail) {
            // dispatch(getServicesSlice())
            navigate('/')
        }
    }, [userEmail, isError, isSuccess])

    const onChange = (e) => {
        setRegisterData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = e => {
        e.preventDefault()

        if (password !== confirmPassword) {
            toast.error("Passwords do not match")
        } else {
            const userData = {
                firstname, lastname, email, phonenumber, password, confirmPassword
            }

            dispatch(register(userData))
        }
        // close the register modal
        props.setModal(null)
    }

    return (
        <div className="modal" style={divStyle}>
            <div className="bg-white w-[80%] max-w-[440px] h-[65%] max-h-[600px] m-auto p-[10px] border border-black rounded-[20px] overflow-auto relative">
                <span
                    className="text-[#aaa] text-[1em] font-bold cursor-pointer absolute right-10 top-5"
                    onClick={closeRegisterModal}>
                    <CloseOutlined className="fixed" />
                </span>

                <div className="flex flex-col h-[80%] my-[10%] mx-[4%]">
                    <p className="text-3xl text-center font-medium">Sign up</p>
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={onSubmit}>
                            <div>
                                <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                    First name
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="firstname"
                                        type="text"
                                        autoComplete="firstname"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Last name
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email Address
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="phonenumber" className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone number
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="phonenumber"
                                        type="text"
                                        autoComplete="phonenumber"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                    Confirm password
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="confirmPassword"
                                        type="password"
                                        autoComplete="confirmPassword"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Sign up
                                </button>
                            </div>
                        </form>

                        <p className="my-10 text-center text-sm text-gray-500">
                            Already a member?{' '}
                            <a onClick={() => props.setModal("login")} className="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                Login
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register