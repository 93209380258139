import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import {
    useSelector,
    useDispatch
} from 'react-redux';


import NextBookingTab from './tabs/bookings/NextBookings';
import PastBookingTab from './tabs/bookings/PastBookings';
import Users from './tabs/bookings/Users';
import { nextBookings, pastBookings, getAllUsers } from '../features/booking/booking.slice';

const { TabPane } = Tabs;

const BookingsPage = () => {
    const dispatch = useDispatch();
    const { allowedToManage } = useSelector(state => state.auth);


    useEffect(() => {
        dispatch(nextBookings());
        dispatch(pastBookings());
        dispatch(getAllUsers());
    }, [])
    return (
        allowedToManage ?
            <div className='mx-3 md:mx-10 lg:mx-32 my-[100px]'>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Next Bookings" key="1">
                        <NextBookingTab />
                    </TabPane>
                    <TabPane tab="Past Bookings" key="2">
                        <PastBookingTab />
                    </TabPane>
                    <TabPane tab="Clients" key="3">
                        <Users />
                    </TabPane>
                </Tabs>
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>
    )
}

export default BookingsPage;