import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    Select,
    Button,
    TimePicker
} from 'antd';
import dayjs from 'dayjs';


import { fetchWorkingHoursSlice, updateWorkingHoursSlice } from '../../../features/workingHours/working-hours.slice';

const MySelectComponent = () => {
    const dispatch = useDispatch();
    const { allowedToManage } = useSelector(state => state.auth);

    const { workingHours } = useSelector(state => state.workingHours);

    const [workingHoursState, setWorkingHoursState] = useState(workingHours);
    const [updateData, setUpdateData] = useState([])
    const [form] = Form.useForm();

    const handleOnChange = (id, values) => {
        const startTime = values && dayjs(values[0]).format('HH:mm');
        const endTime = values && dayjs(values[1]).format('HH:mm');
        const data = {
            id,
            hours: values ? [startTime, endTime] : null
        }
        setUpdateData([...updateData, data]);
    }

    const handleOnConfirm = () => {
        if (updateData.length > 0) {
            dispatch(updateWorkingHoursSlice(updateData));
        }
    }

    useEffect(() => {
        dispatch(fetchWorkingHoursSlice());
    }, []);

    // Update Select values when workingHoursState changes
    useEffect(() => {
        const formValues = workingHoursState?.reduce((acc, { id, hours }) => {
            acc[id] = hours; // Map the current hours to the corresponding form field
            return acc;
        }, {});
        form.setFieldsValue(formValues);
    }, [workingHoursState]);

    useEffect(() => {
        setWorkingHoursState(workingHours)
    }, [workingHours]);

    // Warn user on reload or navigation away
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Chrome requires this line
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [form]);

    return (
        allowedToManage ?
            <div className='mx-3 max-w-[100%] md:max-w-[70%]'>
                <Form
                    form={form}
                    initialValues={{

                    }}
                >
                    {workingHoursState?.map(({ id, day, hours }) => {
                        const dayName = day?.charAt().toUpperCase() + day?.slice(1);
                        const startTime = hours ? dayjs(hours[0], 'HH:mm') : null;
                        const endTime = hours ? dayjs(hours[1], 'HH:mm') : null;

                        return (
                            <div
                                className='flex h-10 mb-4'
                            >
                                <Form.Item
                                    key={id}
                                    label={dayName}
                                    className='text-xl font-bold'
                                    name={id}
                                >
                                </Form.Item>
                                <TimePicker.RangePicker
                                    className='h-8'
                                    defaultValue={[startTime, endTime]} // Default start and end time
                                    format="HH:mm"
                                    onChange={(values) => handleOnChange(id, values)}
                                />
                            </div>)
                    })}
                    <Form.Item
                        className='w-full mt-10 flex justify-end items-end'
                    >
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='flex items-center text-xl font-bold bg-blue-600 hover:bg-blue-500 text-white rounded-lg'
                            onClick={handleOnConfirm}
                        >Confirm</Button>
                    </Form.Item>
                </Form>
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>
    );
};

export default MySelectComponent;
