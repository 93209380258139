import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import servicesDetails from './services-details.service'

const initialState = {
    serviceDetails: [],
    bookedService: {},

    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',

    createIsError: false,
    createIsSuccess: false,
    createIsLoding: false,
    createMessage: '',

    updateIsError: false,
    updateIsSuccess: false,
    updateIsLoding: false,
    updateMessage: '',

    deleteIsError: false,
    deleteIsSuccess: false,
    deleteIsLoding: false,
    deleteMessage: ''
}

// Create a new service
export const createServiceSlice = createAsyncThunk(
    'create/serviceDetails', async (data, thunkAPI) => {
        try {
            const createService = await servicesDetails.createService(data);

            return createService
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// update service
export const updateServiceSlice = createAsyncThunk(
    'update/serviceDetails', async ({ data, id }, thunkAPI) => {
        try {

            const updateService = await servicesDetails.updateService(data, id);

            return updateService
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// delete service
export const deleteServiceSlice = createAsyncThunk(
    'delete/serviceDetails', async (id, thunkAPI) => {
        try {

            const deleteService = await servicesDetails.deleteService(id)

            return deleteService
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get all services
export const getServicesSlice = createAsyncThunk(
    'get/serviceDetails', async (undefined, thunkAPI) => {
        try {

            const getServices = await servicesDetails.getServices()

            return getServices
        } catch (error) {

            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const servicesSlice = createSlice({
    name: 'serviceDetails',
    initialState,
    reducers: {
        reset: state => initialState,
        resetCreate: state => {
            state.createIsError = false
            state.createIsLoding = false
            state.createIsSuccess = false
            state.createMessage = ''
        },
        resetUpdate: (state, action) => {
            state.updateIsError = false
            state.updateIsSuccess = false
            state.updateIsLoding = false
            state.updateMessage = ''
        },
        resetDelete: state => {
            state.deleteIsError = false
            state.deleteIsLoding = false
            state.deleteIsSuccess = false
            state.deleteMessage = ''
        },
        changeBookService: (state, action) => {
            state.bookedService = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(createServiceSlice.pending, state => {
                state.createIsLoding = true
            })
            .addCase(createServiceSlice.fulfilled, (state, action) => {
                state.createIsLoding = false
                state.createIsSuccess = true
                state.serviceDetails.push(action.payload)
                state.createMessage = action.payload.message
            })
            .addCase(createServiceSlice.rejected, (state, action) => {
                state.createIsLoding = false
                state.createIsError = true
                state.createMessage = action.payload.message
            })

            .addCase(updateServiceSlice.pending, state => {
                state.updateIsLoding = true
            })
            .addCase(updateServiceSlice.fulfilled, (state, action) => {
                state.updateIsLoding = false
                state.updateIsSuccess = true
                state.updateIsError = false
                state.updateMessage = action.payload.message
                state.serviceDetails = state.serviceDetails.reduce((acc, curr) => {
                    if (curr.id == action.payload.id) {
                        acc.push(action.payload)
                    } else {
                        acc.push(curr)
                    }
                    return acc
                }, [])
            })
            .addCase(updateServiceSlice.rejected, (state, action) => {
                state.updateIsLoding = false
                state.updateIsError = true
                state.updateMessage = action.payload
            })

            .addCase(deleteServiceSlice.pending, state => {
                state.deleteIsLoding = true
            })
            .addCase(deleteServiceSlice.fulfilled, (state, action) => {
                state.deleteIsLoding = false
                state.deleteIsSuccess = true
                state.serviceDetails = state.serviceDetails.filter(service => service.id !== action.payload.id)
                state.deleteMessage = action.payload.message
            })
            .addCase(deleteServiceSlice.rejected, (state, action) => {
                state.deleteIsLoding = false
                state.deleteIsError = true
                state.deleteMessage = action.payload
            })

            .addCase(getServicesSlice.pending, state => {
                state.isLoading = true
            })
            .addCase(getServicesSlice.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.serviceDetails = action.payload
            })
            .addCase(getServicesSlice.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset, resetCreate, resetUpdate, resetDelete, changeBookService } = servicesSlice.actions
export default servicesSlice.reducer