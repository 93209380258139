import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import workingHoursService from "./working-hours.service";


const initialState = {
    workingHours: null,

    // fetch working hours
    isWorkingHoursError: false,
    isWorkingHoursLoading: false,
    isWorkingHoursSuccess: false,
    workingHoursMessage: null,

    // update working hours of a day
    isUpdateWorkingHoursError: false,
    isUpdateWorkingHoursLoading: false,
    isUpdateWorkingHoursSuccess: false,
    updateWorkingHoursMessage: null,

    // unavailable dates
    isUnavailableDateError: false,
    isUnavailableDateLoading: false,
    isUnavailableDateSuccess: false,
    unavailableDates: null,
    unavailableDatesMessage: null,

    // available hours of a day
    isAvailableHoursOfDayError: false,
    isAvailableHoursOfDayLoading: false,
    isAvailableHoursOfDaySuccess: false,
    availableHoursOfDay: null,
    availableHoursOfDayMessage: null,
}

// fetch all working hours
export const fetchWorkingHoursSlice = createAsyncThunk(
    'working-hours', async (_, thunkAPI) => {
        try {
            const workingHours = await workingHoursService.fetchWorkingHours();

            return workingHours;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

// update working hours of any day
export const updateWorkingHoursSlice = createAsyncThunk(
    'update/working-hours', async (data, thunkAPI) => {
        try {
            const updateWorkingHours = await workingHoursService.updateWorkingHours(data);

            return updateWorkingHours;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

// fetch unavailable dates that are ahead of today's date
export const unavailableDatesSlice = createAsyncThunk(
    'unavailable-dates', async (_, thunkAPI) => {
        try {
            const unavailableDates = await workingHoursService.unavailableDates();

            return unavailableDates;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get available hours that belongs to one specific day while booking a service
export const availableHoursOfDaySlice = createAsyncThunk(
    'available-hours', async (date, thunkAPI) => {
        try {
            const availableHours = await workingHoursService.availableHoursOfDay(date);

            return availableHours;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const workingHoursSlice = createSlice({
    name: 'workingHours',
    initialState,
    reducers: {
        resetUpdateWorkingHours: state => {
            state.isUpdateWorkingHoursError = false;
            state.isUpdateWorkingHoursLoading = false;
            state.isUpdateWorkingHoursSuccess = false;
            state.updateWorkingHoursMessage = null;
        }
    },
    extraReducers: builder => {
        builder
            // fetch working hours
            .addCase(fetchWorkingHoursSlice.pending, state => {
                state.isWorkingHoursLoading = true;
            })
            .addCase(fetchWorkingHoursSlice.fulfilled, (state, action) => {
                state.isWorkingHoursLoading = false;
                state.isWorkingHoursSuccess = true;
                state.workingHours = action.payload?.data;
            })
            .addCase(fetchWorkingHoursSlice.rejected, (state, action) => {
                state.isWorkingHoursLoading = false;
                state.isWorkingHoursError = true;
                state.workingHoursMessage = action.payload;
            })

            // update working hours of a day
            .addCase(updateWorkingHoursSlice.pending, state => {
                state.isUpdateWorkingHoursLoading = false;
            })
            .addCase(updateWorkingHoursSlice.fulfilled, (state, action) => {
                state.isUpdateWorkingHoursLoading = false;
                state.isUpdateWorkingHoursSuccess = true;
                state.workingHours = action.payload?.data;
                state.updateWorkingHoursMessage = action.payload?.message;
            })
            .addCase(updateWorkingHoursSlice.rejected, (state, action) => {
                state.isUpdateWorkingHoursLoading = false;
                state.isUpdateWorkingHoursError = true;
                state.updateWorkingHoursMessage = action.payload;
            })

            // fetch unavailable dates that are ahead of today's date
            .addCase(unavailableDatesSlice.pending, state => {
                state.isUnavailableDateLoading = true;
            })
            .addCase(unavailableDatesSlice.fulfilled, (state, action) => {
                state.isUnavailableDateLoading = false;
                state.isUnavailableDateSuccess = true;
                state.unavailableDates = action.payload;
            })
            .addCase(unavailableDatesSlice.rejected, (state, action) => {
                state.isUnavailableDateLoading = false;
                state.isUnavailableDateError = true;
                state.unavailableDatesMessage = action.payload;
            })

            // Get available hours that belongs to one specific day while booking a service
            .addCase(availableHoursOfDaySlice.pending, state => {
                state.isAvailableHoursOfDayLoading = true;
            })
            .addCase(availableHoursOfDaySlice.fulfilled, (state, action) => {
                state.isAvailableHoursOfDayLoading = false;
                state.isAvailableHoursOfDaySuccess = true;
                state.availableHoursOfDay = action.payload;
            })
            .addCase(availableHoursOfDaySlice.rejected, (state, action) => {
                state.isAvailableHoursOfDayLoading = false;
                state.isAvailableHoursOfDaySuccess = false;
                state.isAvailableHoursOfDayError = true;
                state.availableHoursOfDayMessage = action.payload;
            })

    }
})

export const { resetUpdateWorkingHours } = workingHoursSlice.actions;
export default workingHoursSlice.reducer;