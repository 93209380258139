import axios from "axios";
import Cookies from "js-cookie";

// Create an instance of axios
const API = axios.create({
    baseURL: "/api/ubumenyi/",
})

// Add a request interceptor
API.interceptors.request.use(
    config => {
        // Get access_token from localStorage
        const token = Cookies.get("tycoon_access_token");
        if (token) {
            // Set the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    async error => {
        return await error;
    }
);

// Response interceptor
API.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/'; // Call the handleUnauthorized method
        }
        return error;
    }
);

export default API;