import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
import homeImageService from './home.image.service';

const initialState = {
    homeImagesData: null,
    homeImageIsError: false,
    homeImageIsSuccess: false,
    homeImageIsLoading: false,

    createHomeImageIsError: false,
    createHomeImageIsLoading: false,
    createHomeImageIsSuccess: false,
    createHomeImageMessage: null,

    deleteHomeImageIsError: false,
    deleteHomeImageIsLoading: false,
    deleteHomeImageIsSuccess: false,
    deleteHomeImageMessage: null,
}

// Create home image
export const createHomeImageSlice = createAsyncThunk(
    'create/home-image', async (data, thunkAPI) => {
        try {
            const createHomeImage = await homeImageService.createHomeImage(data);

            return createHomeImage;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// get all images
export const findAllImagesSlice = createAsyncThunk(
    'home-images', async (_, thunkAPI) => {
        try {
            const findImages = await homeImageService.findAll();

            return findImages;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// delete image
export const deleteImageSlice = createAsyncThunk(
    'delete/home-image', async (id, thunkAPI) => {
        try {
            const deleteImage = await homeImageService.deleteImage(id);

            return deleteImage;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const imageSlices = createSlice({
    name: 'homeImages',
    initialState,
    reducers: {
        // reset create home images
        resetCreateHomeImage: state => {
            state.createHomeImageIsError = false
            state.createHomeImageIsLoading = false
            state.createHomeImageIsSuccess = false
            state.createHomeImageMessage = null
        },

        // reset find all home images
        resetFindAllHomeImages: state => {
            state.homeImageIsError = false
            state.homeImageIsSuccess = false
            state.homeImageIsLoading = false
        },

        // reset delete home image
        resetDeleteHomeImage: state => {
            state.deleteHomeImageIsError = false
            state.deleteHomeImageIsLoading = false
            state.deleteHomeImageIsSuccess = false
            state.deleteHomeImageMessage = null
        }
    },
    extraReducers: builder => {
        builder
            // create home image
            .addCase(createHomeImageSlice.pending, state => {
                state.createHomeImageIsLoading = true
            })
            .addCase(createHomeImageSlice.fulfilled, (state, action) => {
                state.createHomeImageIsLoading = false;
                state.createHomeImageIsSuccess = true;
                state.homeImagesData = action.payload?.data;
                state.createHomeImageMessage = action.payload?.message;
            })
            .addCase(createHomeImageSlice.rejected, (state, action) => {
                state.createHomeImageIsLoading = false;
                state.createHomeImageIsError = true;
                state.createHomeImageMessage = action.payload;
            })

            // get home images
            .addCase(findAllImagesSlice.pending, state => {
                state.homeImageIsLoading = true;
            })
            .addCase(findAllImagesSlice.fulfilled, (state, action) => {
                state.homeImageIsLoading = false;
                state.homeImageIsSuccess = true;
                state.homeImagesData = action.payload?.data;
            })
            .addCase(findAllImagesSlice.rejected, (state, action) => {
                state.homeImageIsLoading = false;
                state.homeImageIsError = true;
            })

            // delete home image
            .addCase(deleteImageSlice.pending, state => {
                state.deleteHomeImageIsLoading = true
            })
            .addCase(deleteImageSlice.fulfilled, (state, action) => {
                state.deleteHomeImageIsLoading = false;
                state.deleteHomeImageIsSuccess = true;
                state.homeImagesData = action.payload?.data;
                state.deleteHomeImageMessage = action.payload?.message;
            })
            .addCase(deleteImageSlice.rejected, (state, action) => {
                state.deleteHomeImageIsLoading = false;
                state.deleteHomeImageIsError = true;
                state.deleteHomeImageMessage = action.payload;
            })
    }
})

export const {
    resetCreateHomeImage,
    resetFindAllHomeImages,
    resetDeleteHomeImage
} = imageSlices.actions;
export default imageSlices.reducer;