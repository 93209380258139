import api from '../../utils/baseURL'

// Create a holiday
const createHoliday = async (data) => {
    const response = await api.post(`holidays`, data);

    return response.data;
}

// fetch holidays
const fetchHolidays = async () => {
    const response = await api.get(`holidays`);

    return response.data;
}

// Update a holiday
const updateHolidays = async (id, data) => {
    const response = await api.patch(`holidays/${id}`, data);

    return response.data;
}

// Delete a holiday
const deleteHolidays = async (id) => {
    const response = await api.delete(`holidays/${id}`);

    return response.data;
}

export default { createHoliday, fetchHolidays, updateHolidays, deleteHolidays }