import api from '../../utils/baseURL';

// Create home image
const createHomeImage = async (data) => {
    const response = await api.post(`/home-images`, data);

    return response.data;
};

// get all images
const findAll = async () => {
    const response = await api.get(`/home-images`);

    return response.data;
};

// delete image
const deleteImage = async (id) => {
    const response = await api.delete(`/home-images/${id}`);

    return response.data;
};

export default { createHomeImage, findAll, deleteImage };