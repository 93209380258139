import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

import { login } from "../features/auth/auth.slice";
import { getServicesSlice } from "../features/services/services-details.slice";

const Login = props => {

    const divStyle = {
        display: props.displayModal === "login" ? 'block' : 'none'
    }

    function registerUser() {
        props.setModal("signup")
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })

    const { email, password } = loginData

    const { userEmail, isError, isAuthSuccess } = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuthSuccess || userEmail) {
            // dispatch(getServicesSlice())
            navigate('/')
        }

        // dispatch(reset())
    }, [userEmail, isError, isAuthSuccess])

    const onChange = e => {
        setLoginData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = e => {
        e.preventDefault()

        const userData = {
            email, password
        }

        props.setModal(null)

        dispatch(login(userData))
    }

    return (
        <div className="modal" style={divStyle}>
            <div className="bg-white w-[80%] max-w-[440px] h-[65%] max-h-[600px] m-auto p-[10px] border border-black rounded-[20px] overflow-auto relative">
                <span
                    className="text-[#aaa] text-[1em] font-bold cursor-pointer absolute right-10 top-5"
                    onClick={() => props.setModal(null)}>
                    <CloseOutlined className="fixed" />
                </span>

                <div className="flex flex-col h-[80%] my-[10%] mx-[4%]">
                    <p className="text-3xl text-center font-medium">Sign in</p>
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={onSubmit}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email Address
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={onChange}
                                    />
                                </div>

                                <div className="mt-2 text-sm self-end">
                                    <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>

                        <p className="my-10 text-center text-sm text-gray-500">
                            Not a member?{' '}
                            <a className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={registerUser}>
                                Register
                            </a>
                        </p>
                        {/* <Register displayRegisterModal={registerModal}
                            closeRegisterModal={selectRegisterModal}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login