import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";


import authService from "./auth.service";


function userDetails() {
    const accessToken = Cookies.get("tycoon_access_token");
    const decodedToken = accessToken ? jwtDecode(accessToken) : { role: '' };
    const { sub, email, role, phonenumber } = decodedToken;
    return { sub, email, role, phonenumber };
}

const initialState = {
    userId: userDetails()?.sub,
    userEmail: userDetails()?.email,
    isAuthError: false,
    isAuthSuccess: false,
    isAuthLoading: false,
    isAuthLogout: false,
    authMessage: '',

    allowedToManage: userDetails()?.role === "superadmin" || userDetails()?.role === "manager" ? true : false,
    phonenumber: userDetails()?.phonenumber ?? ""
}

// Register user
export const register = createAsyncThunk("auth/register", async (registerObject, thunkAPI) => {
    try {
        const register = await authService.register(registerObject);

        return register
    } catch (error) {
        const authMessage = (error.response.data.message || error.response.data || error.response) || error.toString();

        return thunkAPI.rejectWithValue(authMessage); // reject and send the authMessage as the payload
    }
})

// Login user
export const login = createAsyncThunk("auth/login", async (loginObject, thunkAPI) => {
    try {
        const login = await authService.login(loginObject);

        return login
    } catch (error) {
        const authMessage = (error.response.data.message || error.response.data || error.response) || error.toString()

        return thunkAPI.rejectWithValue(authMessage) // reject and send the authMessage as the payload
    }
})

// Logout
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    try {
        return await authService.logout()
    } catch (error) {
        const authMessage = await (error.response.data.message || error.response.data || error.response) || error.toString()

        return thunkAPI.rejectWithValue(authMessage)
    }

})

// Create a slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // used to reset the state to the default values
        reset: (state) => {
            state.isAuthLoading = false
            state.isAuthSuccess = false
            state.isAuthError = false
            state.isAuthLogout = false
            state.authMessage = ''
        }
    },

    // For async and thunk functions
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, state => {
                state.isAuthLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isAuthLoading = false
                state.isAuthSuccess = true
                state.userId = userDetails()?.sub
                state.userEmail = userDetails()?.email
                state.allowedToManage = userDetails()?.role === "superAdmin" || userDetails()?.role === "manager" ? true : false
                state.authMessage = action.payload.message
                state.phonenumber = userDetails()?.phonenumber
            })
            .addCase(register.rejected, (state, action) => {
                state.isAuthLoading = false
                state.isAuthError = true
                state.authMessage = action.payload
                state.userId = null
                state.userEmail = null
                state.phonenumber = ""
            })


            .addCase(login.pending, state => {
                state.isAuthLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthLoading = false
                state.isAuthSuccess = true
                state.userId = userDetails()?.sub
                state.userEmail = userDetails()?.email
                state.authMessage = action.payload.message
                state.allowedToManage = userDetails()?.role === "superadmin" || userDetails()?.role === "manager" ? true : false
                state.phonenumber = userDetails()?.phonenumber
            })
            .addCase(login.rejected, (state, action) => {

                console.log(action.payload);
                state.isAuthLoading = false
                state.isAuthError = true
                state.authMessage = action.payload
                state.userId = null
                state.userEmail = null
                state.phonenumber = ""
            })


            .addCase(logout.fulfilled, (state, action) => {
                state.userId = null
                state.userEmail = null
                state.isAuthLogout = true
                state.authMessage = action.payload.data
                state.allowedToManage = false
                state.phonenumber = ""
            })
            .addCase(logout.rejected, (state, action) => {
                state.isAuthError = true
                state.authMessage = action.payload
            })
    }
})

export const { reset } = authSlice.actions;
export default authSlice.reducer;