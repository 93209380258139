import React, { useEffect, useState } from 'react';
import {
    CloseOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {
    Image,
    Upload,
    Spin
} from 'antd';
import {
    useSelector,
    useDispatch
} from 'react-redux';


import {
    switchMultipleImageModalOff
} from '../features/components/component.slice';
import {
    createHomeImageSlice,
    deleteImageSlice
} from '../features/homeImages/home.image.slice';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const App = () => {
    const dispatch = useDispatch();
    const { allowedToManage } = useSelector(state => state.auth);
    const { multipleImageStatus } = useSelector(state => state.switchComponent);
    const { homeImagesData, createHomeImageIsLoading, deleteHomeImageIsLoading } = useSelector(state => state.homeImages);
    const divStyle = {
        display: multipleImageStatus ? 'block' : 'none'
    }

    function closeModal() {
        dispatch(switchMultipleImageModalOff());
    }

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ file }) => {
        const formData = new FormData();

        formData.append('url', file.originFileObj);
        if (file.originFileObj) {
            dispatch(createHomeImageSlice(formData));
        }
    };
    const onRemove = ({ id }) => {
        if (window.confirm("Are you sure you want to remove the image?")) {
            dispatch(deleteImageSlice(id));
        }
    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    // useEffect(() => {
    //     dispatch(findAllImagesSlice());
    // }, [])
    return (allowedToManage &&
        <div className="modal" style={divStyle}>
            <div className="flex items-center justify-center bg-gray-50 w-[95%] md:w-[80%] max-w-[640px] h-[60%] md:h-[40%] max-h-[600px] m-auto p-[8px] border border-black rounded-[20px] overflow-auto relative"
            >
                <span
                    className="text-[#aaa] text-[1em] font-bold cursor-pointer absolute right-10 top-5"
                    onClick={closeModal}>
                    <CloseOutlined className="fixed" />
                </span>
                {
                    (createHomeImageIsLoading || deleteHomeImageIsLoading) ? (
                        <Spin />
                    ) : (
                        <Upload
                            action={false}
                            listType="picture-circle"
                            fileList={homeImagesData}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            onRemove={onRemove}
                        >
                            {homeImagesData?.length >= 8 ? null : uploadButton}
                        </Upload>
                    )
                }
                {previewImage && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </div>
        </div >
    );
};
export default App;