import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination, Scrollbar, A11y, EffectFade, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'
import 'pure-react-carousel/dist/react-carousel.es.css'
import {
    Skeleton
} from "antd";


import kidhair from "../images/kidhair.jpg"
import grayhair from "../images/grayhair.jpeg"
import lines from "../images/lines.jpeg"
import highTaper from "../images/hightaper.jpg"
import { findAllImagesSlice } from "../features/homeImages/home.image.slice";


const imageData = [
    {
        label: "Image 1",
        alt: "image1",
        url: highTaper,
        bg: "bg-[#638f8d]"
    },
    {
        label: "Image 2",
        alt: "image2",
        url: kidhair,
        bg: "bg-[#5376d4]"
    },
    {
        label: "Image 3",
        alt: "image3",
        url: lines,
        bg: "bg-[#944c30]"
    },
    {
        label: "Image 4",
        alt: "image4",
        url: grayhair,
        bg: "bg-[#632398]"
    },
];

export default function HomeSlide() {
    const dispatch = useDispatch()
    const { homeImagesData, homeImageIsLoading } = useSelector(state => state.homeImages);
    const data = (homeImagesData && Array.isArray(homeImagesData) && homeImagesData?.length > 0) ? homeImagesData : imageData;

    const swiperRef = useRef(null)
    const handleMouseEnter = () => {
        // swiperRef.current.swiper.autoplay.stop()
    }
    const handleMouseLeave = () => {
        // swiperRef.current.swiper.autoplay.start()
    }

    useEffect(() => {
        dispatch(findAllImagesSlice())
    }, [])

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Swiper
                ref={swiperRef}
                modules={[Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                slidesPerView={1}
                pagination
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                speed={1000}
                loop={true}
                grabCursor={true}
                effect={'fade'}
            // cubeEffect={{
            //     shadow: true,
            //     slideShadows: true,
            //     shadowOffset: 20,
            //     shadowScale: 0.94,
            // }}
            >{homeImageIsLoading ?
                <Skeleton.Image
                    active
                    style={{
                        width: "100vw",
                        height: "75vh"
                    }}
                /> :
                data?.map(({ url, bg }, index) => (
                    <SwiperSlide key={index}>
                        <div className="md:flex md:flex-row flex flex-col justify-around items-center max-w-[100vw] mx-auto h-[75vh] md:gap-x-10 gap-y-7">
                            <div className="w-[100%] h-[100%] relative">
                                <img src={url} alt="Home" className="w-[100%] h-[100%] shadow-[0px_0px_10px_#444] bg-[#a19661] object-cover" />
                                {/* <div className="flex flex-col gap-y-8 absolute inset-x-0 bottom-20 text-white">
                                        <h1 className="text-center text-[3em]">
                                            <strong>Title</strong>
                                        </h1>
                                        <p className="text-center text-lg md:mx-[200px] mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis facilis et aperiam a esse. Architecto dolore aperiam excepturi dolorum temporibus!</p>
                                    </div> */}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}