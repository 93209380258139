import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    InstagramOutlined,
    MailOutlined,
    PhoneOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    UploadOutlined
} from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import {
    Popconfirm,
    Spin
} from "antd";


import EditModal from "../components/editServices";
import CreateServiceModal from "../components/createService";
import HomeSlide from "../components/HomeSlideImages";
import Login from "../components/Login";
import Register from "../components/Register";
import logo from "../images/logo.png";
import { getServicesSlice, deleteServiceSlice, changeBookService } from "../features/services/services-details.slice";
import {
    switchEditOn,
    switchCreateOn,
    switchMultipleImageModalOn,
} from "../features/components/component.slice";
import { cancelBooking } from "../features/booking/booking.slice";
import MultipleImages from '../components/multipleImages';

const Home = () => {
    const dispatch = useDispatch()

    const {
        serviceDetails,
        createIsLoding,
        updateIsLoding,
        deleteIsLoding
    } = useSelector(state => state.serviceDetails);
    const { userId, allowedToManage } = useSelector(state => state.auth);
    const { bookingId, email } = useParams();

    const [activePopUp, setActivePopUp] = useState(null);
    const [modal, setModal] = useState(null);

    const selectEditModal = () => {
        dispatch(switchEditOn())
    }

    const selectCreateModal = () => {
        dispatch(switchCreateOn())
    }

    const handleDeleteItem = (id) => {
        dispatch(deleteServiceSlice(id));
    }

    useEffect(() => {
        dispatch(getServicesSlice())
    }, [])

    useEffect(() => {
        (email && bookingId) && dispatch(cancelBooking({ bookingId, email }));
    }, [email, bookingId])

    const handleCurrentService = (value) => {
        dispatch(changeBookService(value))
    }

    return (
        <>
            <div className="w-screen my-[88px]">
                {/* Title */}
                <HomeSlide />
                {allowedToManage && <UploadOutlined
                    className='w-[95vw] mt-2 flex justify-end cursor-pointer text-blue-500 text-2xl'
                    onClick={() => dispatch(switchMultipleImageModalOn())}
                />}
                <MultipleImages />
                {/* Our Services */}
                <div id="ourServices"></div>
                <div className="max-w-[100vw] mx-auto h-fit mt-20 flex flex-col items-center justify-center gap-y-16">
                    <h3 className="text-[2em] md:text-[3em] font-bold">Our Services</h3>
                    <div className="flex flex-col items-center justify-between h-full w-full gap-y-20">
                        {(createIsLoding || updateIsLoding || deleteIsLoding) ?
                            <Spin /> :
                            serviceDetails?.map((item, idx) => {
                                return (
                                    <div key={idx} className="w-[90%] h-[100%] flex flex-row items-start justify-center gap-x-8 md:py-16 p-5 bg-slate-100 rounded-lg md:p-0 md:bg-white md:rounded-none">

                                        <div className={`w-full flex flex-col items-center md:w-full md:justify-between ${idx % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"} gap-y-10`}>
                                            {/* PC --- Title, description and Book now */}
                                            <div className={`grid flex-col w-full items-center ${idx % 2 === 0 ? "md:ml-10" : "md:mr-10"} md:items-start`}>

                                                {allowedToManage ?
                                                    <div className="justify-self-end flex md:hidden">
                                                        <EditOutlined onClick={() => {
                                                            selectEditModal()
                                                            setActivePopUp(item)
                                                        }} />
                                                        <EditModal
                                                            dataContents={activePopUp}
                                                        />
                                                    </div>
                                                    : <></>
                                                }

                                                <p className={`${idx % 2 === 0 ? "md:justify-self-end" : "md:justify-self-start"} justify-self-start text-sm text-sky-500`}>{item.duration}min</p>
                                                <p className="justify-self-center font-semibold text-2xl md:text-4xl">{item.title}</p>
                                                <p className="text-sm text-center md:text-left md:text-lg opacity-80">{item.description}</p>
                                                <div className={`flex items-end ${allowedToManage ? 'justify-between' : 'justify-center'} w-full`}>
                                                    <Link to="/book"
                                                        onClick={() => { handleCurrentService(item) }}
                                                        className="hidden mt-10 w-28 h-10 md:flex items-center justify-center bg-slate-700 text-white hover:text-gray-200 transition-all ease-in-out duration-300 cursor-pointer font-semibold text-xl"
                                                    >Book now</Link>

                                                    {allowedToManage ?
                                                        <div className="hidden md:flex justify-between w-[10%]">
                                                            <EditOutlined onClick={() => {
                                                                selectEditModal()
                                                                setActivePopUp(item)
                                                            }} />
                                                            <EditModal
                                                                dataContents={activePopUp}
                                                            />
                                                            <Popconfirm
                                                                title="Are you sure?"
                                                                onConfirm={() => handleDeleteItem(item.id)}
                                                                okButtonProps={{ style: { backgroundColor: "#2563eb" } }}
                                                            >
                                                                <DeleteOutlined
                                                                    className="scale-125 text-gray-300 cursor-pointer hover:text-red-400 ease-in-out duration-300"
                                                                />
                                                            </Popconfirm>
                                                        </div> :
                                                        <></>
                                                    }
                                                </div>
                                            </div>

                                            {/* PC & MOBILE --- Video */}
                                            <div className="md:max-w-[55%] w-fit">
                                                <img className="w-[22em] h-[22em] md:w-[60em] md:h-[30em] object-cover" alt={item.title} src={item.document} />
                                                {/* <video className="w-full h-full object-fill" muted src={dataContents[0].video} /> */}
                                            </div>

                                            {/* MOBILE --- Book now & Delete */}
                                            <div className="md:hidden w-28 h-10 flex items-center justify-center bg-slate-700 text-white hover:text-gray-200 transition-all ease-in-out duration-300 cursor-pointer"
                                                onClick={() => { handleCurrentService(item) }}
                                            >
                                                <Link to="/book">Book now</Link>
                                            </div>
                                            <Login displayModal={modal} setModal={setModal} />
                                            <Register displayRegisterModal={modal} setModal={setModal} />
                                            {allowedToManage ?
                                                <div className="md:hidden flex w-full justify-end items-center -mt-10">
                                                    <Popconfirm
                                                        title="Are you sure"
                                                        onConfirm={() => handleDeleteItem(item.id)}
                                                        okButtonProps={{ style: { backgroundColor: "#2563eb" } }}
                                                    >
                                                        <DeleteOutlined
                                                            className="scale-125 text-gray-300 cursor-pointer hover:text-red-400 ease-in-out duration-300"
                                                        />
                                                    </Popconfirm>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {allowedToManage ?
                            <div className="w-full h-10 flex justify-center items-center">
                                <PlusCircleOutlined
                                    onClick={() => {
                                        selectCreateModal()
                                    }}
                                    className="scale-[2.3] cursor-pointer text-gray-300 hover:text-gray-400 ease-in-out duration-300"
                                />
                                <CreateServiceModal />
                            </div> : <></>}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="bg-[#242424] w-screen h-fit flex">
                <footer className="flex flex-col items-center md:flex-row md:items-center md:justify-around max-w-[1400px] w-11/12 mx-auto h-[100%] my-10 gap-y-5">
                    <div className="w-[95%] md:w-[30%] h-[80%] flex justify-center items-center">
                        <img src={logo} alt="logo" className="w-[15%] rounded-[50%]" />
                        <p className="text-white font-bold text-3xl">Tycoon Salon<span className="block text-sm font-normal">SHALOM NINZIZA</span></p>
                    </div>
                    <div className="w-[95%] md:w-[40%] h-[80%] flex justify-between items-start">
                        <div className="flex flex-col items-start text-blue-500">
                            <p className="text-[#eee] font-semibold text-[100%]">Contact Us:</p>
                            <a href="mailto:shalomcutshair@gmail.com" className="cursor-pointer"><MailOutlined /><span> shalomcutshair@gmail.com</span></a>
                            <a className="cursor-pointer"><PhoneOutlined /><span> +257 65 575 454</span></a>
                        </div>
                        <div className="flex flex-col items-start">
                            <p className="text-[#eee] font-semibold text-[100%]">Follow Us:</p>
                            <a href="https://www.instagram.com/shalom_cuts_hair/" target="_" className="cursor-pointer hover:scale-150 ease-in-out duration-300 text-blue-500"><InstagramOutlined /></a>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )

}

export default Home