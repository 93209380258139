import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { createServiceSlice } from "../features/services/services-details.slice"
import { switchCreateOff } from "../features/components/component.slice"
import { CloseOutlined } from "@ant-design/icons"

const CreateService = () => {

    const dispatch = useDispatch()
    const { switchCreate } = useSelector(state => state.switchComponent)
    const { allowedToManage } = useSelector(state => state.auth);

    const [formData, setFormData] = useState(null)
    const { title, description, duration, price } = formData != null ? formData : { title: '', description: '', duration: '', price: '' }

    function closeModal() {
        // e.stopPropagation()
        if (formData) {
            if (window.confirm("Warning! There are unsaved changes.")) {
                dispatch(switchCreateOff())
                setFormData(null)
            }
        } else {
            dispatch(switchCreateOff())
            setFormData(null)
        }
    }

    const divStyle = {
        display: switchCreate ? 'block' : 'none'
    }

    const onChangeData = (e) => {
        setFormData((prevState) => (
            {
                ...prevState,
                [e.target.name]: e.target.value
            }
        ))
    }

    const onChangeFile = (e) => {
        setFormData((prevState) => (
            {
                ...prevState,
                document: e.target.files[0]
            }
        ))
    }

    const onSubmit = (e) => {
        e.preventDefault() // prevent the default form submission behavior (refreshing or navigating to a new URL)

        const newFormData = new FormData()

        // Append all form data fields
        Object.entries(formData).forEach(([key, value]) => {
            newFormData.append(key, value)
        })

        dispatch(createServiceSlice(newFormData))
        dispatch(switchCreateOff())
        setFormData(null)
    }

    return (allowedToManage ?
        <div className="modal" style={divStyle}>
            <div className="bg-white w-[80%] max-w-[640px] h-[90%] md:h-[70%] max-h-[600px] m-auto p-[10px] border border-black rounded-[20px] overflow-auto relative"
            // onClick={e => e.stopPropagation()} /*stop click event from bubbling up from the children container to the parent container*/
            >
                <span
                    className="text-[#aaa] text-[1em] font-bold cursor-pointer absolute right-10 top-5"
                    onClick={closeModal}>
                    <CloseOutlined className="fixed" />
                </span>
                <div className="grid h-[100%] my-[7%] mx-[2%]">
                    <div className="text-[1.3rem] font-bold justify-self-center self-center w-fit h-fit mb-0">
                        <p>Create Service</p>
                    </div>
                    <div className="self-start">
                        <form className="grid h-[80%] gap-y-8" action="" onSubmit={onSubmit} >
                            <div className="flex flex-col w-[100%]">
                                <p className="text-xl relative">
                                    <span className="text-red-500 text-[10px] absolute top-0">*</span><span className="ml-[4.5px]">Title:</span>
                                </p>
                                <input name="title" type="text" placeholder="Title"
                                    className="w-[90%] px-4 border rounded-md" value={title} onChange={onChangeData} required />
                            </div>

                            <div className="flex flex-col max-w-[90%]">
                                <label className="text-md relative">
                                    <span className="text-red-500 text-[10px] absolute top-0">*</span><span className="ml-[4.5px]">Description:</span>
                                </label>
                                <textarea className="border px-4 rounded-md w-[100%]" name="description" type="text" value={description} rows={4} cols={40} onChange={onChangeData} required />
                            </div>

                            <div className="flex flex-col w-[100%]">
                                <p className="text-xl relative">
                                    <span className="text-red-500 text-[10px] absolute top-0">*</span><span className="ml-[4.5px]">Duration:</span>
                                </p>
                                <input name="duration" type="text" placeholder="Duration in minutes"
                                    className="w-[90%] px-4 border rounded-md" value={duration} onChange={onChangeData} required />
                            </div>

                            <div className="flex flex-col w-[100%]">
                                <p className="text-xl relative">
                                    <span className="text-red-500 text-[10px] absolute top-0">*</span><span className="ml-[4.5px]">Price:</span>
                                </p>
                                <input name="price" type="text" placeholder="Price"
                                    className="w-[90%] px-4 border rounded-md" value={price} onChange={onChangeData} required />
                            </div>

                            <div>
                                <input className="border rounded-md w-[90%]" name="document" type="file" onChange={onChangeFile} required />
                            </div>
                            <button type="submit" value="Update"
                                className="w-fit p-2 rounded-lg bg-blue-600 text-white justify-self-end cursor-pointer hover:bg-blue-500 transition-all duration-300 shadow-[0px_1px_6px_#999]">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div> : <></>
    )
}

export default CreateService
