import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";


import Navs from "./components/Navs";
import Home from "./pages/Home";
import BookPage from "./pages/BookPage";
import BookingsPage from "./pages/Bookings";
import Schedule from "./pages/Schedule";
import CancelBooking from "./components/CancelBooking"
import NotFound from './components/NotFound';
import { reset, logout } from "./features/auth/auth.slice";
import { resetCreate, resetUpdate, resetDelete } from "./features/services/services-details.slice";
import { resetBooking, resetUpdateBookingStatus, resetUsers } from './features/booking/booking.slice';
import { resetUpdateWorkingHours } from './features/workingHours/working-hours.slice';
import { resetCreateHolidays, resetUpdateHolidays, resetDeleteHolidays } from './features/holidays/holidays.slice';
import { switchOffLoginBeforeBooking } from './features/components/component.slice';
import {
  resetCreateHomeImage,
  resetDeleteHomeImage,
} from './features/homeImages/home.image.slice';
function App() {
  const dispatch = useDispatch()

  function sessionExpired() {
    dispatch(logout())
    toast.info('Session expired please authenticate again')
  }

  const {
    isError,
    message,
    createIsSuccess,
    createIsError,
    createMessage,
    updateIsError,
    updateIsSuccess,
    updateMessage,
    deleteIsSuccess,
    deleteIsError,
    deleteMessage
  } = useSelector(state => state.serviceDetails);

  const {
    isAuthSuccess,
    isAuthError,
    isAuthLogout,
    authMessage
  } = useSelector(state => state.auth);

  const {
    isCreateBookingError,
    isCreateBookingSuccess,
    createBookingMessage,
    isUpdateBookingStatusSuccess,
    isUpdateBookingStatusError,
    updateBookingStatusMessage,
    isUsersError,
    usersMessage,
  } = useSelector(state => state.booking);

  const {
    isUpdateWorkingHoursError,
    isUpdateWorkingHoursSuccess,
    updateWorkingHoursMessage,
    isWorkingHoursError,
    workingHoursMessage
  } = useSelector(state => state.workingHours);

  const {
    createHolidaysIsError,
    createHolidaysIsSuccess,
    createHolidaysMessage,
    updateHolidaysIsError,
    updateHolidaysIsSuccess,
    updateHolidaysMessage,
    deleteHolidaysIsError,
    deleteHolidaysIsSuccess,
    deleteHolidaysMessage
  } = useSelector(state => state.holidays);

  const {
    createHomeImageIsSuccess,
    createHomeImageIsError,
    createHomeImageMessage,
    deleteHomeImageIsSuccess,
    deleteHomeImageIsError,
    deleteHomeImageMessage,
  } = useSelector(state => state.homeImages);

  const {
    loginBeforeBooking
  } = useSelector(state => state.switchComponent)

  useEffect(() => {
    if (isUsersError) {
      toast.error(usersMessage)
    }

    if (createHomeImageIsError) {
      toast.error(createHomeImageMessage)
    }

    if (createHomeImageIsSuccess) {
      toast.success(createHomeImageMessage)
    }

    if (deleteHomeImageIsError) {
      toast.error(deleteHomeImageMessage)
    }

    if (deleteHomeImageIsSuccess) {
      toast.success(deleteHomeImageMessage)
    }

    if (loginBeforeBooking) {
      toast.info('Please login before booking')
    }

    if (createHolidaysIsError) {
      toast.error(createHolidaysMessage)
    }

    if (createHolidaysIsSuccess) {
      toast.success(createHolidaysMessage)
    }

    if (updateHolidaysIsError) {
      toast.error(updateHolidaysMessage)
    }

    if (updateHolidaysIsSuccess) {
      toast.success(updateHolidaysMessage)
    }

    if (isUpdateWorkingHoursSuccess) {
      toast.success(updateWorkingHoursMessage);
    }

    if (isWorkingHoursError) {
      toast.error(workingHoursMessage);
    }

    if (deleteHolidaysIsError) {
      toast.error(deleteHolidaysMessage)
    }

    if (deleteHolidaysIsSuccess) {
      toast.success(deleteHolidaysMessage)
    }

    if (isUpdateWorkingHoursError) {
      toast.error(updateWorkingHoursMessage)
    }

    if (isCreateBookingSuccess) {
      toast.success(createBookingMessage)
    }

    if (isCreateBookingError) {
      toast.error(createBookingMessage)
    }

    if (isError) {
      if (message === "EXPIRED_TOKEN") {
        sessionExpired()
      } else {
        toast.error(message)
      }
    }

    if (createIsSuccess) {
      toast.success(createMessage)
    }

    if (createIsError) {
      if (createMessage === "EXPIRED_TOKEN") {
        sessionExpired()
      } else {
        toast.error(createMessage)
      }
    }

    if (updateIsSuccess) {
      toast.success(updateMessage)
    }

    if (updateIsError) {
      if (updateMessage === "EXPIRED_TOKEN") {
        sessionExpired()
      } else {
        toast.error(updateMessage)
      }
    }

    if (deleteIsSuccess) {
      toast.success(deleteMessage)
    }

    if (deleteIsError) {
      if (deleteMessage === "EXPIRED_TOKEN") {
        sessionExpired()
      } else {
        toast.error(deleteMessage)
      }
    }

    if (isAuthError) {
      if (authMessage === "EXPIRED_TOKEN") {
        sessionExpired()
      } else {
        toast.error(authMessage)
      }
    }

    if (isAuthSuccess) {
      toast.success(authMessage)
    }

    if (isAuthLogout) {
      toast.info(authMessage)
    }

    if (isUpdateBookingStatusSuccess) {
      toast.success(updateBookingStatusMessage)
    }

    if (isUpdateBookingStatusError) {
      toast.error(updateBookingStatusMessage)
    }

    dispatch(resetUsers())
    dispatch(switchOffLoginBeforeBooking())
    dispatch(reset())
    dispatch(resetCreate())
    dispatch(resetUpdate())
    dispatch(resetDelete())
    dispatch(resetBooking())
    dispatch(resetUpdateBookingStatus())
    dispatch(resetUpdateWorkingHours())
    dispatch(resetCreateHolidays())
    dispatch(resetUpdateHolidays())
    dispatch(resetDeleteHolidays())
    dispatch(resetCreateHomeImage())
    dispatch(resetDeleteHomeImage())
  }, [
    isUsersError,
    loginBeforeBooking,
    isError,
    createIsSuccess,
    createIsError,
    updateIsError,
    updateIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    isAuthSuccess,
    isAuthError,
    isAuthLogout,


    isCreateBookingSuccess,
    isCreateBookingError,
    isUpdateBookingStatusSuccess,
    isUpdateBookingStatusError,

    isWorkingHoursError,
    isUpdateWorkingHoursSuccess,


    createHolidaysIsError,
    createHolidaysIsSuccess,
    updateHolidaysIsError,
    updateHolidaysIsSuccess,
    deleteHolidaysIsError,
    deleteHolidaysIsSuccess,


    createHomeImageIsError,
    deleteHomeImageIsError,
    createHomeImageIsSuccess, ,
    deleteHomeImageIsSuccess, ,
  ])

  return (
    <>
      <Router>
        <Navs />
        <CancelBooking />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book" element={<BookPage />} />
          <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/booking/cancel/:bookingId/:email" element={<Home />} />
          {/* Catch-all route for 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
