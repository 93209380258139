import { useEffect, useState } from "react";
import { MenuOutlined, CloseOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Space, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logo from "../images/logo.png";
import Login from "./Login";
import Register from "./Register";
import { logout } from '../features/auth/auth.slice';

function Navs() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userEmail, allowedToManage } = useSelector(state => state.auth)

    const onLogin = () => {
        setModal('login')
    }

    const onRegister = () => {
        setModal('signup')
    }

    const onLogout = () => {
        const confirmlogout = window.confirm('Are you sure you want to log out?')
        if (confirmlogout) {
            dispatch(logout())
            setModal(null)
            navigate('/')
        }
    }

    const [showMenu, setShowMenu] = useState(false);

    const [modal, setModal] = useState(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const items = userEmail ? [
        {
            label: <a onClick={onLogout}>Logout <LogoutOutlined /></a>,
            key: '1'
        }
    ] : [
        {
            label: <a onClick={onLogin}><LoginOutlined /> Login</a>,
            key: '1'
        },
        {
            label: <a onClick={onRegister}><UserOutlined /> Register</a>,
            key: '2'
        }
    ]

    useEffect(() => {
        function handleResize() {
            const sizing = window.innerWidth
            if (sizing >= 768) {
                setShowMenu(false)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <nav className="bg-[#239879] w-screen shadow-[0px_1px_10px_#444] py-4 top-0 fixed z-10">
                <div className="flex items-center max-w-[1400px] mx-auto w-11/12 h-14 gap-x-10 max-[900px]:justify-between">

                    {allowedToManage &&
                        <div className="md:hidden flex items-center justify-center ">
                            <button className="flex text-xl" onClick={toggleMenu}>
                                {!showMenu ? <MenuOutlined /> : <CloseOutlined />}
                            </button>
                        </div>
                    }

                    {/* LOGO */}
                    <div className="flex justify-center">
                        <div className="w-14 rounded-full">
                            <img className="w-full h-full rounded-full object-cover cursor-pointer" src={logo} alt="Logo"
                                onClick={() => {
                                    navigate('/')
                                    window.scrollTo({ top: 0, behavior: "smooth" })
                                }}
                            />
                        </div>
                    </div>

                    {/* LINKS --- Desktop */}
                    <ul className="hidden w-full md:flex justify-end items-center gap-x-16">
                        {userEmail &&
                            <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out">
                                <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                                    Home
                                </Link>
                            </li>
                        }
                        {allowedToManage && <>
                            <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"><Link to="/bookings" smooth={true} duration={500}>Bookings</Link></li>
                            <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"><Link to="/schedule" smooth={true} duration={500}>Schedule</Link></li>
                        </>}

                        {/* <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"
                        > */}
                        <Dropdown
                            className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"
                            menu={{ items }}
                            trigger={['click']}
                            placement="bottom"
                        >
                            <a onClick={e => e.preventDefault()}>
                                <Space>
                                    {userEmail ? <Avatar icon={<UserOutlined />}
                                        className={userEmail ? "border-green-700" : ''}
                                    /> :
                                        <LoginOutlined />}
                                </Space>
                            </a>
                        </Dropdown>

                        <Login displayModal={modal} setModal={setModal} />
                        <Register displayRegisterModal={modal} setModal={setModal} />
                        {/* </li> */}

                    </ul>

                    {/* LINKS --- Mobile */}
                    <div className="md:hidden flex justify-between md:justify-end gap-x-6">
                        {/* <p className="hover:text-red-600 cursor-pointer text-xl transition-all duration-300 ease-in-out"
                        > */}
                        <Dropdown
                            className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"
                            menu={{ items }}
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <a onClick={e => e.preventDefault()}>
                                <Space>
                                    {userEmail ? <Avatar icon={<UserOutlined />}
                                        className={userEmail ? "border-green-700" : ''}
                                    /> :
                                        <LoginOutlined />}
                                </Space>
                            </a>
                        </Dropdown>

                        <Login displayModal={modal} setModal={setModal} />
                        <Register displayRegisterModal={modal} setModal={setModal} />
                        {/* </p> */}

                        {/* <p className="flex text-xl hover:text-white items-center cursor-pointer transition-all duration-300 ease-in-out"
                            onClick={() => selectRegisterModal()}>
                            <UserOutlined className="text-lg flex" /><span className="hidden md:flex">Register</span>
                        </p> */}
                        {/* <Register displayRegisterModal={registerModal} closeRegisterModal={selectRegisterModal} /> */}

                    </div>

                </div>

                {/* Bar menu */}
                {userEmail &&
                    <div className={!showMenu ? "bg-[#4f6b64] h-screen w-[50%] left-0 -translate-x-full ease-in-out duration-300 my-4 absolute" : "ease-in-out duration-300 bg-[#4f6b64] h-screen w-[50%] absolute left-0 my-4"}>
                        <ul className="h-full flex flex-col items-center gap-y-10 py-6">
                            {userEmail &&
                                <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out" onClick={() => {
                                    navigate('/')
                                    window.scrollTo({ top: 0, behavior: "smooth" })
                                }}>
                                    Home
                                </li>
                            }
                            {allowedToManage && <>
                                <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"><Link to="/bookings" smooth={true} duration={500}>Bookings</Link></li>
                                <li className="hover:text-white cursor-pointer text-xl transition-all duration-300 ease-in-out"><Link to="/schedule" smooth={true} duration={500}>Schedule</Link></li>
                            </>}
                        </ul>
                    </div>
                }
            </nav>
        </>
    )
}

export default Navs