import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);

const CancelBooking = () => {
    const { cancelBookingData } = useSelector(state => state.booking);

    useEffect(() => {
    }, [cancelBookingData])

    if (!cancelBookingData) {
        return null
    }

    const title = cancelBookingData?.service?.title;
    const date = cancelBookingData?.date;

    const dateTime = dayjs(date).utc().format('ddd, DD MMM YYYY HH:mm');

    return (
        <Alert
            className="flex justify-center w-full z-10 fixed"
            message='Information'
            type="info"
            description={cancelBookingData === "NotFound" ? <>Booking not found.</> : cancelBookingData === "ToLate" ? <>It is too late to cancel your booking</> :
                <>You <strong className="text-red-400">canceled</strong> successfully your booking <strong>{title}</strong> service scheduled on <span style={{ color: 'blue' }}>{dateTime}</span>.</>}
            showIcon
            closable
        />
    )
}

export default CancelBooking;