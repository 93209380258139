import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookingServices from "./booking.service";

const initialState = {

    // Booking states
    isCreateBookingError: false,
    isCreateBookingSuccess: false,
    isCreateBookingLoading: false,
    createBookingMessage: null,
    createBookingData: null,

    // unavailable dates states
    isUnavailableDatesError: false,
    isUnavailableDatesSuccess: false,
    isUnavailableDatesLoading: false,
    unavailableDatesData: null,
    unavailableDatesMessage: null,

    // next-bookings states
    isNextBookingError: false,
    isNextBookingSuccess: false,
    isNextBookingLoading: false,
    nextBookingData: null,
    nextBookingMessage: null,

    // past-bookings states
    isPastBookingError: false,
    isPastBookingSuccess: false,
    isPastBookingLoading: false,
    pastBookingData: null,
    pastBookingMessage: null,

    // update-bookings status states
    isUpdateBookingStatusError: false,
    isUpdateBookingStatusSuccess: false,
    isUpdateBookingStatusLoading: false,
    updateBookingStatusMessage: null,

    // get user up-coming booking
    isCancelBookingError: false,
    isCancelBookingLoading: false,
    isCancelBookingSuccess: false,
    cancelBookingData: null,

    // get all users
    users: null,
    isUsersLoading: false,
    isUsersSuccess: false,
    isUsersError: false,
    usersMessage: null,
}

// ---------------------------------------- save booking -------------------------------------------------
export const bookService = createAsyncThunk(
    'booking', async (bookingData, thunkAPI) => {
        try {
            const bookService = await bookingServices.bookService(bookingData);

            return bookService
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- unavailable dates that are ahead ------------------------------
export const unavailableDates = createAsyncThunk(
    'unavailable-dates', async (_, thunkAPI) => {
        try {
            const unavailableDates = await bookingServices.unavailableDates();

            return unavailableDates;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- get next bookings ------------------------------------------------
export const nextBookings = createAsyncThunk(
    'next-bookings', async (_, thunkAPI) => {
        try {
            const nextBookings = await bookingServices.getNextBookings();

            return nextBookings;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- get past bookings ------------------------------------------------
export const pastBookings = createAsyncThunk(
    'past-bookings', async (_, thunkAPI) => {
        try {
            const pastBookings = await bookingServices.getPastBookings();

            return pastBookings;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- Update status booking ------------------------------------------------
export const updateBookingStatus = createAsyncThunk(
    `update-booking-status`, async ({ id, data }, thunkAPI) => {
        try {
            const updateBookingStatus = await bookingServices.updateBookingStatus(id, data);

            return updateBookingStatus;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- Get all users ------------------------------------------------
export const getAllUsers = createAsyncThunk(
    `users`, async (_, thunkAPI) => {
        try {
            const users = await bookingServices.getAllUsers();

            return users;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

// ---------------------------------------- cancel booking ------------------------------------------------
export const cancelBooking = createAsyncThunk(
    `cancel-booking`, async ({ bookingId, email }, thunkAPI) => {
        try {
            const cancelBooking = await bookingServices.cancelBooking(bookingId, email);

            return cancelBooking;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const bookingSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        // reset create bookings states to initial states
        resetBooking: state => {
            state.createBookingData = null
            state.isCreateBookingLoading = false
            state.isCreateBookingError = false
            state.isCreateBookingSuccess = false
            state.createBookingMessage = null
        },

        // reset next bookings states to initial states
        resetNextBooking: state => {
            state.nextBookingData = null
            state.isNextBookingLoading = false
            state.isNextBookingError = false
            state.isNextBookingSuccess = false
            state.nextBookingMessage = null
        },

        // reset past bookings states to initial states
        resetPastBooking: state => {
            state.pastBookingData = null
            state.isPastBookingError = false
            state.isPastBookingLoading = false
            state.isPastBookingSuccess = false
            state.pastBookingMessage = null
        },

        // reset update Booking status to initial states
        resetUpdateBookingStatus: state => {
            state.isUpdateBookingStatusError = false
            state.isUpdateBookingStatusLoading = false
            state.isUpdateBookingStatusSuccess = false
            state.updateBookingStatusMessage = null
        },

        // reset cancel booking states to initial states
        resetUse: state => {
            state.isCancelBookingError = false
            state.isCancelBookingLoading = false
            state.isCancelBookingSuccess = false
            state.cancelBookingData = null
        },

        // reset users to initial states
        resetUsers: state => {
            state.users = null
            state.isUsersError = false
            state.isUsersLoading = false
            state.isUsersSuccess = false
            state.usersMessage = null
        }
    },
    extraReducers: builder => {
        builder
            // ---------------------------------------- booking extraReducers -------------------------------------------------
            .addCase(bookService.pending, state => {
                state.isCreateBookingLoading = true;
            })
            .addCase(bookService.fulfilled, (state, action) => {
                state.isCreateBookingLoading = false
                state.isCreateBookingSuccess = true
                state.createBookingData = action.payload?.saveBooking
                state.createBookingMessage = action.payload?.message
            })
            .addCase(bookService.rejected, (state, action) => {
                state.isCreateBookingLoading = false
                state.isCreateBookingError = true
                state.createBookingMessage = action.payload
            })

            // ---------------------------------------- unavailable dates extraReducers -------------------------------------------------
            .addCase(unavailableDates.pending, state => {
                state.isUnavailableDatesLoading = true
            })
            .addCase(unavailableDates.fulfilled, (state, action) => {
                state.isUnavailableDatesLoading = false
                state.isUnavailableDatesSuccess = true
                state.unavailableDatesData = action.payload
            })
            .addCase(unavailableDates.rejected, (state, action) => {
                state.isUnavailableDatesLoading = false
                state.isUnavailableDatesSuccess = false
                state.unavailableDatesMessage = action.payload
            })

            // ---------------------------------------- nextBookings extraReducers -------------------------------------------------
            .addCase(nextBookings.pending, state => {
                state.isNextBookingLoading = true
            })
            .addCase(nextBookings.fulfilled, (state, action) => {
                state.isNextBookingLoading = false
                state.isNextBookingSuccess = true
                state.nextBookingData = action.payload
            })
            .addCase(nextBookings.rejected, (state, action) => {
                state.isNextBookingLoading = false
                state.isNextBookingSuccess = false
                state.nextBookingMessage = action.payload
            })

            // ---------------------------------------- pastBookings extraReducers -------------------------------------------------
            .addCase(pastBookings.pending, state => {
                state.isPastBookingLoading = true
            })
            .addCase(pastBookings.fulfilled, (state, action) => {
                state.isPastBookingLoading = false
                state.isPastBookingSuccess = true
                state.pastBookingData = action.payload?.data
            })
            .addCase(pastBookings.rejected, (state, action) => {
                state.isPastBookingLoading = false
                state.isPastBookingSuccess = false
                state.pastBookingMessage = action.payload
            })

            // ---------------------------------------- update booking status extraReducers -------------------------------------------------
            .addCase(updateBookingStatus.pending, state => {
                state.isUpdateBookingStatusLoading = true
            })
            .addCase(updateBookingStatus.fulfilled, (state, action) => {
                state.isUpdateBookingStatusLoading = false
                state.isUpdateBookingStatusSuccess = true
                state.pastBookingData = state.pastBookingData?.reduce((acc, curr) => {
                    if (curr.id == action.payload.data.id) {
                        acc.push(action.payload.data);
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, [])
                state.updateBookingStatusMessage = action?.payload?.message
            })
            .addCase(updateBookingStatus.rejected, (state, action) => {
                state.isUpdateBookingStatusLoading = false
                state.isUpdateBookingStatusError = true
                state.updateBookingStatusMessage = action.payload
            })

            // ---------------------------------------- cancel booking ------------------------------------------
            .addCase(cancelBooking.pending, state => {
                state.isCancelBookingLoading = true
            })
            .addCase(cancelBooking.fulfilled, (state, action) => {
                state.isCancelBookingLoading = false
                state.isCancelBookingSuccess = true
                state.cancelBookingData = action.payload
            })
            .addCase(cancelBooking.rejected, state => {
                state.isCancelBookingLoading = false
                state.isCancelBookingError = true
            })

            // ---------------------------------------- users extraReducers -------------------------------------------------
            .addCase(getAllUsers.pending, state => {
                state.isUsersLoading = true
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.isUsersLoading = false
                state.isUsersSuccess = true
                state.users = action.payload?.data
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.isUsersLoading = false
                state.isUsersSuccess = false
                state.usersMessage = action.payload
            })
    }
})

export const { resetBooking, resetUpdateBookingStatus, resetUsers } = bookingSlice.actions;
export default bookingSlice.reducer;