import React from 'react';
import { Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const Users = () => {
    const { users } = useSelector(state => state.booking);
    const { allowedToManage } = useSelector(state => state.auth);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'PhoneNumber',
            dataIndex: 'phonenumber',
            key: 'phoneNumber',
        },
    ];

    const data = users?.map(data => {
        const results = {
            key: data?.id,
            name: data?.name,
            email: data?.email,
            phonenumber: data?.phonenumber,
        }

        return results;
    })

    return (
        allowedToManage ?
            <div className='flex flex-col items-center md:mx-3 lg:mx-10 '>
                <p className='mb-5 text-xl font-bold'>Clients</p>
                <Table
                    className='overflow-x-auto w-full'
                    columns={columns}
                    dataSource={data}
                    pagination={true}
                    scroll={{ x: 'max-content' }}
                />
            </div> :

            <div className='w-[100vw] h-[100vh] gap-x-40 flex flex-col items-center justify-center'>
                <p className='text-9xl font-bold'>403</p>
                <p className='text-2xl'>Forbidden</p>
            </div>
    )
}

export default Users;